import React, { useEffect, useState } from "react";

export default function useScreenDuration() {
  const [time, setTime] = useState(0);
  const [pageLoadTime, setPageLoadTime] = useState(Date.now());
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isActive) {
        setTime(Date.now() - pageLoadTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isActive, pageLoadTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden);
      if (!document.hidden) {
        setPageLoadTime(Date.now() - time);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [time]);

  const formattedTime = React.useMemo(() => {
    return getFormattedTime(time);
  }, [time]);
  return { formattedTime };
}

export const getFormattedTime = (time) => {
  const minutes = Math.floor(time / 60000);
  const hrs = Math.floor(time / 3600000);
  const seconds = ((time % 60000) / 1000).toFixed(0);
  return `${Number(hrs) < 10 ? "0" : ""}${hrs}:${
    Number(minutes) < 10 ? "0" : ""
  }${minutes}:${Number(seconds) < 10 ? "0" : ""}${seconds}`;
};
