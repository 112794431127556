import { FC } from "react";

import { StyledButton } from "./button.styles";
import { ButtonProps } from "./types";

const Button: FC<ButtonProps> = ({ color, size, icon, suffixIcon, label, ...rest }) => (
  <StyledButton color={color} size={size} {...rest}>
    {icon && <span className="icon">{icon}</span>}
    {label}
    {suffixIcon && <span className="suffix">{suffixIcon}</span>}
  </StyledButton>
);

export default Button;

Button.defaultProps = {
  color: "primary",
  size: "md",
  icon: "",
  suffixIcon: "",
  label: "",
};
