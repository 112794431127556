import styled, { css } from "styled-components";
import { space } from "styled-system";

import { ButtonProps } from "./types";

const prevSizes: Record<string, "xs" | "sm" | "md" | "lg" | "xl"> = {
  xs: "xs",
  sm: "xs",
  md: "sm",
  lg: "md",
  xl: "lg",
};

export const StyledButton = styled.button<ButtonProps>`
  ${space}
  height: ${({ size }) => getSize[prevSizes[size!]]["height"] + "px"};
  font-size: ${({ size }) => getSize[prevSizes[size!]]["fontSize"] + "px"};
  border-radius: ${({ size }) => getSize[prevSizes[size!]]["radius"] + "px"};
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  padding: 0 ${({ size }) => getSize[prevSizes[size!]]["height"] / 2}px;
  transition: all 0.4s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-family: inherit;
  background-color: unset;
  @media (min-width: ${({ theme, size }) => theme.breakpoints[size!]}) {
    height: ${({ size }) => getSize[size!]["height"] + "px"};
    font-size: ${({ size }) => getSize[size!]["fontSize"] + "px"};
    border-radius: ${({ size }) => getSize[size!]["radius"] + "px"};
    padding: 0 ${({ size }) => getSize[size!]["height"] / 2}px;
  }
  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.label &&
    css`
      min-width: ${() => (getSize[props.size!]["height"] / 4) * 12}px;
    `}

  & span {
    display: inherit;

    & > :nth-of-type(1) {
      font-size: ${({ size }) => getSize[size!]["fontSize"] + 6 + "px"};
    }

    & svg {
      /* fill: currentColor; */
      transition: fill 0.4s ease-in-out;
      width: ${({ size }) => getSize[size!]["fontSize"] + 6 + "px"};
      height: ${({ size }) => getSize[size!]["fontSize"] + 6 + "px"};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  & .icon {
    margin-right: 8px;
  }
  & .suffix {
    margin-left: 8px;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const getSize = {
  xs: {
    height: 36,
    fontSize: 14,
    radius: 4,
  },
  sm: {
    height: 40,
    fontSize: 14,
    radius: 8,
  },
  md: {
    height: 48,
    fontSize: 16,
    radius: 8,
  },
  lg: {
    height: 56,
    fontSize: 16,
    radius: 8,
  },
  xl: {
    height: 72,
    fontSize: 18,
    radius: 8,
  },
};
