import { FC } from "react";

import { StyledContainer } from "./container.styles";
import { ContainerProps } from "./types";

const Container: FC<ContainerProps> = ({ children, className, ...rest }) => {
  return (
    <StyledContainer {...rest} className={className}>
      {children}
    </StyledContainer>
  );
};

export default Container;

Container.defaultProps = {
  className: "",
};
