import Link from "next/link";
import SVG from "react-inlinesvg";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import Typography from "@/design-system/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

const OuterBoxStyle = styled.section`
  background-color: ${theme.colors.grayWarm[200]};
  padding-top: 16px;
  padding-bottom: 16px;
`;
const LeftItem = styled.div`
  display: flex;
  align-items: center;
  grid-column: span 12;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 8;
  }
`;
const RightItem = styled.div`
  grid-column: span 12;
  display: flex;
  justify-content: start;
  /* margin-top: 16px; */
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0;
    grid-column: span 4;
    justify-content: flex-end;
  }
`;

const AndCopytypography = styled(Typography)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;
const AndCopytypographyMd = styled(Typography)`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
const ContainerStyle = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const LinkTypo = styled(Typography)`
  &:hover {
    color: ${theme.colors.gray[900]};
  }
`;

const FooterInfoBar = () => {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");

  return (
    <OuterBoxStyle>
      <ContainerStyle>
        <GridContainer alignItems={"center"}>
          <LeftItem>
            <Link className="link__" href={"/privacy-policy"}>
              <LinkTypo
                varient="bodyxs"
                weightVarient="semibold"
                color={theme.colors.gray[600]}
                mr={"24px"}
              >
                Privacy Policy
              </LinkTypo>
            </Link>
            <Box>
              <SVG src={`/icons/misc/div_footer.svg`} />
            </Box>
            <Link className="link__" href={"/terms-and-conditions"}>
              <LinkTypo
                varient="bodyxs"
                weightVarient="semibold"
                color={theme.colors.gray[600]}
                mr={"24px"}
                ml={"24px"}
              >
                Terms of Use
              </LinkTypo>
            </Link>

            {!isNotIndia && (
              <AndCopytypography color={theme.colors.gray[600]} varient="captions" mr={"24px"}>
                ©2023 Oneistox India Pvt. Ltd.{" "}
              </AndCopytypography>
            )}
          </LeftItem>

          <RightItem>
            {!isNotIndia && (
              <AndCopytypographyMd varient="captions" mr={"41px"} color={theme.colors.gray[600]}>
                ©2023 Oneistox India Pvt. Ltd.{" "}
              </AndCopytypographyMd>
            )}
            <Typography varient="captions" color={theme.colors.gray[600]}>
              All Rights Reserved
            </Typography>
          </RightItem>
        </GridContainer>
      </ContainerStyle>
    </OuterBoxStyle>
  );
};

export default FooterInfoBar;
