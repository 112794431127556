import styled from "styled-components";
import { space } from "styled-system";

import { ContainerProps } from "./types";

export const StyledContainer = styled.div<ContainerProps>`
  ${space}
  margin-left: 1rem;
  margin-right: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 5rem;
    margin-right: 5rem;
    max-width: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-left: 10rem;
    margin-right: 10rem;
    max-width: initial;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    max-width: 1520px;
    margin-left: auto;
    margin-right: auto;
  }
`;
