import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import SVG from "react-inlinesvg";
import styled from "styled-components";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const LeftItemStyle = styled.div`
  grid-column: span 12;
  display: inline-flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 5;
    margin-top: -91px;
  }
`;

const MeetingImageStyle = styled.div`
  /* height: 223px; */
  margin-bottom: 58px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    order: 0;
    display: inline-flex;
  }
`;

const SocialOuterContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    order: 3;
    display: inline-flex;
    flex-direction: column;
  }
`;

const BuildSkillText = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 80px;
  }
`;

const FooterLeftItem = ({ disableCta }: { disableCta: boolean }) => {
  return (
    <LeftItemStyle>
      <SocialOuterContainer>
        {!disableCta && (
          <Link className="link__" href={"/"}>
            <Box mb={theme.spacing(6)}>
              <ExportedImage
                alt={"Novatr"}
                height={32}
                width={127}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logo/logo_new.svg`}
              />
            </Box>
          </Link>
        )}
        {disableCta && (
          <Box>
            <Box mb={theme.spacing(6)}>
              <ExportedImage
                alt={"Novatr"}
                height={32}
                width={127}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logo/logo_new.svg`}
              />
            </Box>
          </Box>
        )}
        <Box>
          <Typography mb={24}>
            Join thousands of people who are taking <br />
            AEC by storm with Novatr.
          </Typography>
        </Box>
        <FlexContainer>
          <Box mr={"16px"}>
            <Link href={"https://instagram.com/novatr.official/"} target="_blank">
              <SVG src={`/icons/misc/instagram_footer.svg`} />
            </Link>
          </Box>

          <Box mr={"16px"}>
            <Link href={"https://www.facebook.com/novatrofficial"} target="_blank">
              <SVG src={`/icons/misc/facebook_footer.svg`} />
            </Link>
          </Box>

          <Box mr={"16px"}>
            <Link href={"https://www.linkedin.com/company/novatr/mycompany/"} target="_blank">
              <SVG src={`/icons/misc/linkedin_footer.svg`} />
            </Link>
          </Box>
          <Box>
            <Link href={"https://www.youtube.com/channel/UC43uuiLYbswvwqgaSdepBpg"} target="_blank">
              <SVG src={`/icons/misc/youtube_footer.svg`} />
            </Link>
          </Box>
        </FlexContainer>
      </SocialOuterContainer>

      <MeetingImageStyle>
        {/* <Box > */}
        <ExportedImage
          width={455}
          height={298}
          style={{ maxWidth: "100%", height: "auto" }}
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/footer.png`}
          alt="Dare to Disrupt!"
        />
      </MeetingImageStyle>

      <BuildSkillText>
        <Typography
          color={theme.colors.primary[900]}
          varient="displays"
          weightVarient="semibold"
          as={"h3"}
        >
          Dare to Disrupt
        </Typography>
      </BuildSkillText>
    </LeftItemStyle>
  );
};

export default FooterLeftItem;
